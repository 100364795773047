// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../../../src/templates/home/home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */),
  "component---src-templates-post-post-tsx": () => import("./../../../src/templates/post/post.tsx" /* webpackChunkName: "component---src-templates-post-post-tsx" */),
  "component---src-templates-tags-tags-tsx": () => import("./../../../src/templates/tags/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tags-tsx" */)
}

